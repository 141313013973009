import { Button, Slider } from 'antd'
import { useEffect, useRef, useState } from 'react'

import {
  useClientContext,
  usePlayerContext,
  useVideoDataContext,
  useScheduledPlayerContext,
  useAnalyticContext,
} from '../../hooks'
import { CONTROLS_VISIBILITY_MODE_TYPE } from './types'
import { MIN_GAP_BETWEEN_EVENTS, noop, secondsToHMS, TIMESHIFT_SLIDE_END_PLAY } from '../../utils'

import { DotsMenu } from './DotsMenu'
import { ChapterMenu } from './ChapterMenu'
import Icon, { IconsENUM } from '../UI/Icons/Icon'
import { CurrentTimePopup } from './CurrentTimePopup'
import { ButtonTransparent } from '../UI/ButtonTransparent/ButtonTransparent'

import '../../assets/styles/styles.less'
import { createIsTimeKeyInRangeWithCurrentTime } from '../../utils/interactivity-helpers'

/*
  Stands for:
    - play, pause, etc buttons
    - show slider
    - handle UI interactions
*/
export const VideoControls = () => {
  const { isFullScreen, shouldCheckVideoForReadyState } = useClientContext()
  const {
    volume,
    isPaused,
    isMuted,
    videoElement,
    isPlayDisabled,
    isVideoReady,
    isVideoFinished,
    forceChangedTime,
    controlVisibilityMode,
    playHandler,
    pauseHandler,
    volumeMuteHandler,
    videoReadyHandler,
    fullScreenHandler,
    rewindOnTimeHandler,
    volumeChangeHandler,
    videoFinishedHandler,
    controlVisibilityModeHandler,
  } = usePlayerContext()
  const { videoSchedule } = useVideoDataContext()
  const { rewindToPrevSlideHandler, rewindToNextSlideHandler } = useScheduledPlayerContext()
  const { analyticCollector } = useAnalyticContext()
  const [marks, setMarks] = useState([])
  const [renderedMarks, setRenderedMarks] = useState({})
  const [showTimePopupEvent, setShowTimePopupEvent] = useState(null)
  const [currentTime, setCurrentTime] = useState(0)
  const progressDimsRef = useRef()

  const timeLineValue = !isNaN(videoElement?.duration) ? (currentTime * 100) / videoElement?.duration : 0
  const { isNavigationAllowed } = videoSchedule ?? {}

  useEffect(() => {
    if (!videoElement) return
    // for slider moves on we don't need raf -> ontimeupdate is enough
    // also, because raf works too often we can't use it for setting a state -> max call stack will be exceeded
    const onCurrentTimeChange = () => {
      setCurrentTime(videoElement.currentTime ?? 0)
      // Video duration can be increased dynamically by browser for just tens of milliseconds.
      // But it can prevent trigger events at the end of video (end of last slide).
      // So we force limit it to first know duration.
      if (!isVideoFinished && videoElement.currentTime + MIN_GAP_BETWEEN_EVENTS > videoSchedule?.duration) {
        videoElement.currentTime = Math.max(videoSchedule.duration, videoElement.duration)
        setCurrentTime(videoElement.currentTime)
        videoFinishedHandler(true)
        pauseHandler()
        analyticCollector.stopTimer(true)
        analyticCollector.collectWhenFinished()
      }
    }
    videoElement.ontimeupdate = onCurrentTimeChange
    videoElement.oncanplay = () => videoReadyHandler(true)
    return () => {
      videoElement.ontimeupdate = null
      videoElement.oncanplay = null
    }
  }, [videoElement, videoSchedule, isVideoFinished])

  useEffect(() => {
    if (!videoElement?.readyState) return
    if (!shouldCheckVideoForReadyState) return
    if (isVideoReady) return
    // This is workaround for video.oncanplay event. It's not correctly works on safari
    // When currTime = 0 and have just metadata -> set ready. Only this work on first load
    if (videoElement.currentTime === 0 && videoElement.readyState >= videoElement.HAVE_METADATA) {
      // increase current time for small amount to show first frame on video
      videoElement.currentTime += 0.0001
      videoReadyHandler(true)
    }
    // in other cases set ready if have current data
    if (videoElement.readyState > videoElement.HAVE_CURRENT_DATA) videoReadyHandler(true)
  }, [videoElement?.readyState, videoElement?.currentTime, currentTime, videoSchedule?.duration])

  // need to quickly update timeline slider state, while video.ontimeupdate is still not thrown
  useEffect(() => {
    setCurrentTime(forceChangedTime)
  }, [forceChangedTime])

  // Need to ste isVideoReady to false for spinner appears. True is set on videoElement.oncanplay event
  useEffect(() => {
    if (!videoElement) return
    if (Math.abs(videoElement.currentTime - currentTime) > 2) videoReadyHandler(false)
  }, [videoElement?.currentTime, currentTime])

  useEffect(() => {
    if (!videoSchedule) return
    setMarks(videoSchedule.marks)
    setRenderedMarks(
      Object.fromEntries(
        Object.entries(videoSchedule.timeToMarkMap).map(([key, markData]) => [
          key,
          {
            key: markData.slideId,
            label: markData.isInteractive ? (
              <div className="interactive-mark">
                <Icon name={IconsENUM.bolt} />
              </div>
            ) : (
              <div className="mark-chapter"></div>
            ),
          },
        ]),
      ),
    )
  }, [videoSchedule])

  const onSliderHoverHandler = (e) => {
    if (e.type === 'mouseleave') {
      setShowTimePopupEvent(null)
    } else {
      setShowTimePopupEvent(e)
    }
  }

  const handleControlsVisibility = () => {
    if (controlVisibilityMode === CONTROLS_VISIBILITY_MODE_TYPE.HIDDEN) {
      if (isPaused) {
        controlVisibilityModeHandler(CONTROLS_VISIBILITY_MODE_TYPE.HOVER)
      } else {
        controlVisibilityModeHandler(CONTROLS_VISIBILITY_MODE_TYPE.VISIBLE)
      }
    } else {
      controlVisibilityModeHandler(CONTROLS_VISIBILITY_MODE_TYPE.HIDDEN)
    }
  }

  const handleChangeTime = (value) => {
    if (!videoElement) return
    if (!isNavigationAllowed) return
    const numValue = +value
    if (Number.isNaN(numValue)) return
    const time = numValue * (videoElement.duration / 100)
    rewindOnTimeHandler(time, !isPaused)
  }

  const handlePlayPause = () => {
    if (isPlayDisabled) return
    if (isPaused && isVideoFinished) {
      analyticCollector.stopTimer(true)
      analyticCollector.collectWhenFinished()
      rewindOnTimeHandler(0, true)
    }
    if (isPaused) {
      const { slidesSchedule } = videoSchedule
      const isInRange = createIsTimeKeyInRangeWithCurrentTime(currentTime)
      const isSlideEnd = slidesSchedule.find(({ endTime }) => isInRange(endTime))
      const timeShift = isSlideEnd ? TIMESHIFT_SLIDE_END_PLAY : 0
      playHandler(timeShift)
    } else {
      pauseHandler()
    }
  }

  return (
    <div className="player-controls">
      <div id="menu-container"></div>
      <CurrentTimePopup
        showTimePopupEvent={showTimePopupEvent}
        currentTime={currentTime}
        progressDims={progressDimsRef.current}
      />
      <div className="controls-wrapper">
        <div className="controls" id="controls" data-state={controlVisibilityMode}>
          <div className="progress-wrapper">
            <div ref={progressDimsRef} onMouseMove={onSliderHoverHandler} onMouseLeave={onSliderHoverHandler}>
              <Slider
                defaultValue={0}
                showInfo={false}
                marks={renderedMarks}
                onChange={handleChangeTime}
                value={timeLineValue}
                tooltip={{ open: false }}
              />
            </div>
          </div>
          <div className="buttons-wrapper">
            <div className="left">
              <ButtonTransparent
                a11yDescription="Go to the previous slide"
                className={`button ${currentTime <= 0 || !isNavigationAllowed ? 'disabled' : ''}`}
                onClick={isNavigationAllowed ? () => rewindToPrevSlideHandler(currentTime, !isPaused) : noop}
              >
                <Icon name={IconsENUM.left_arrow_long} />
              </ButtonTransparent>
              <ButtonTransparent
                a11yDescription={isPaused ? (isVideoFinished ? 'Repeat' : 'Play') : 'Pause'}
                className={`play ${isPlayDisabled ? 'disabled-icon' : ''}`}
              >
                <Icon
                  name={
                    isPaused ? (isVideoFinished ? IconsENUM.repeat : IconsENUM.play_rounded) : IconsENUM.pause_rounded
                  }
                  onClick={handlePlayPause}
                />
              </ButtonTransparent>
              <ButtonTransparent
                a11yDescription="Go to the next slide"
                onClick={isNavigationAllowed ? () => rewindToNextSlideHandler(currentTime, !isPaused) : noop}
                className={`button ${isVideoFinished || !isNavigationAllowed ? 'disabled' : ''}`}
              >
                <Icon name={IconsENUM.right_arrow_long} />
              </ButtonTransparent>
            </div>
            <div className="right">
              <div className="volume-wrapper">
                <Button aria-label="Volume" type="text">
                  <Slider
                    value={isMuted ? 0 : volume * 100}
                    onChange={volumeChangeHandler}
                    defaultValue={volume * 100}
                    tooltip={{ open: false }}
                  />
                </Button>
                {/* DO NOT NEST A BUTTON IN A BUTTON! */}
                <ButtonTransparent onClick={() => volumeMuteHandler(!isMuted)}>
                  <Icon name={isMuted ? IconsENUM.muted : IconsENUM.volume} />
                </ButtonTransparent>
              </div>
              <span className="time-wrapper">
                {currentTime ? secondsToHMS(currentTime) : '0:00'} / {secondsToHMS(videoElement?.duration)}
              </span>
              <ChapterMenu marks={marks} />
              <Button
                aria-label={isFullScreen ? 'Return to the normal size' : 'Enter fullscreen'}
                shape="circle"
                type="text"
                onClick={fullScreenHandler}
              >
                <Icon name={isFullScreen ? IconsENUM.normal_screen : IconsENUM.full_screen} />
              </Button>
              <DotsMenu />
            </div>
          </div>
        </div>
      </div>
      <Button
        shape="circle"
        type="text"
        id="controls-collapse-button"
        className="controls-collapse-button"
        data-state={`controls-${controlVisibilityMode}`}
        onClick={handleControlsVisibility}
        aria-label={controlVisibilityMode !== CONTROLS_VISIBILITY_MODE_TYPE.HIDDEN ? 'Hide controls' : 'Show controls'}
      >
        <Icon
          name={`${controlVisibilityMode !== CONTROLS_VISIBILITY_MODE_TYPE.HIDDEN ? IconsENUM.down_chevron : IconsENUM.up_chevron}`}
        />
      </Button>
    </div>
  )
}
