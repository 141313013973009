import { usePlayerContext, useVideoDataContext } from '../../hooks'
import { secondsToHMS } from '../../utils'

const HORIZONTAL_SLIDER_PADDING_SUM = 10
const MAX_POPUP_WIDTH = 200

export const CurrentTimePopup = (props) => {
  const { showTimePopupEvent, progressDims } = props
  const { videoElement } = usePlayerContext()
  const { videoSchedule } = useVideoDataContext()
  const { marks } = videoSchedule ?? {}

  if (!videoElement) return null
  if (!showTimePopupEvent) return null
  if (!progressDims) return null
  if (marks?.length === 0) return null

  const { x, width } = progressDims.getBoundingClientRect()
  const calcWidth = width - x
  const time = ((showTimePopupEvent.clientX - x - HORIZONTAL_SLIDER_PADDING_SUM) * videoElement.duration) / calcWidth
  const chapter = marks?.reduce((result, current, index, array) => {
    if (result) return result
    const next = array[index + 1]
    const isInRange = time >= current.time && (!next || time < next.time)
    if (isInRange && current.chapter) return current.chapter
    return null
  }, null)
  let transform = { transform: `translateX(${showTimePopupEvent.clientX}px)` }

  if (width - showTimePopupEvent.clientX < MAX_POPUP_WIDTH) {
    transform = { transform: `translateX(-${width - showTimePopupEvent.clientX}px)`, right: 15 }
  }
  return (
    <div className="current-time-popup" style={{ bottom: 70, zIndex: 6, ...transform }}>
      <span key="2">{chapter ?? ''}</span>
      <span key="3">{secondsToHMS(time >= 0 ? time : 0)}</span>
    </div>
  )
}
